import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { GrFormPrevious } from "react-icons/gr";

function AdminAdd() {
  const navigate = useNavigate();

  const [centers, setCenters] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [calendars, setCalendars] = useState([]);

  const [newDoctor, setNewDoctor] = useState({
    typeformUrl: '',
    name: '',
    specialization: '',
    centerId: '',
    expressAppointmentEnabled: false,
    calendarId: '',
    notificationEmailAddress: '',
    infoBtnText: '',
    infoBtnLink: ''
  });

  const [newCenter, setNewCenter] = useState({
    alias: '',
    meno: ''
  });

  const baseURL = 'http://144.24.169.245:8080/bezrucovabackend';
  const authToken = localStorage.getItem('authToken');

  useEffect(() => {
    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
    }
    fetch(`${baseURL}/centers/getByPermission`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setCenters(data);
      })
      .catch(error => console.error('Error fetching centers:', error));
  }, []);

  useEffect(() => {
    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
    }
    fetch(`${baseURL}/doctors/getByPermission`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setDoctors(data);
      })
      .catch(error => console.error('Error fetching doctors:', error));
  }, []);

  useEffect(() => {
    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
    }
    fetch(`${baseURL}/calendars/available`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch calendars');
        }
        return response.json();
      })
      .then(data => {
        setCalendars(data);
      })
      .catch(error => console.error('Error fetching calendars:', error));
  }, []);

  const handleNewDoctorInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewDoctor((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleNewCenterInputChange = (e) => {
    const { name, value } = e.target;
    setNewCenter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const confirmNewDoctor = () => {
    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
      }
    
      if (!newDoctor.name.trim()) {
        alert('Prosím, vyplňte meno lekára.');
        return;
      }
      if (!newDoctor.specialization.trim()) {
        alert('Prosím, vyplňte špecializáciu lekára.');
        return;
      }
      if (!newDoctor.centerId) {
        alert('Prosím, vyberte centrum.');
        return;
      }
      if (!newDoctor.calendarId) {
        alert('Prosím, vyberte kalendár.');
        return;
      }
      if (!newDoctor.typeformUrl.trim()) {
        alert('Prosím, vyplňte Typeform URL.');
        return;
      }
      if (!newDoctor.notificationEmailAddress.trim()) {
        alert('Prosím, vyplňte notifikačný email.');
        return;
      }
      if (!newDoctor.infoBtnLink.trim()) {
        alert('Prosím, vyplňte link pre tlačidlo.');
        return;
      }
      if (!newDoctor.infoBtnText.trim()) {
        alert('Prosím, vyplňte informačný text ku tlačidlu.');
        return;
      }

    fetch(`${baseURL}/doctors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify(newDoctor),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response not ok');
      })
      .then(data => {
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
        fetch(`${baseURL}/doctors/getByPermission`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            setDoctors(data);
          })
          .catch(error => console.error('Error fetching doctors:', error));
        setNewDoctor({
          typeformUrl: '',
          name: '',
          specialization: '',
          centerId: '',
          expressAppointmentEnabled: false,
          calendarId: '',
          notificationEmailAddress: '',
          infoBtnText: '',
          infoBtnLink: ''
        });
        alert('Lekár bol úspešne pridaný.');
      })
      .catch(error => {
        console.error('Error adding doctor:', error);
        alert('Chyba pri pridávaní lekára.');
      });
  };

  const confirmNewCenter = () => {
    if (!authToken) {
      console.error('No auth token found. Please login.');
      alert('Chyba: Prosím prihláste sa');
      return;
    }

    if (!newCenter.alias.trim()) {
      alert('Prosím, vyplňte ID centra.');
      return;
    }
    if (!newCenter.meno.trim()) {
      alert('Prosím, vyplňte názov centra.');
      return;
    }

    if (!authToken) {
        console.error('No auth token found. Please login.');
        alert('Chyba: Prosím prihláste sa');
        return;
    }

    fetch(`${baseURL}/centers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        alias: newCenter.alias,
        meno: newCenter.meno
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response not ok');
      })
      .then(data => {
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
        fetch(`${baseURL}/centers/getByPermission`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            setCenters(data);
          })
          .catch(error => console.error('Error fetching centers:', error));
        setNewCenter({
          alias: '',
          meno: ''
        });
        alert('Centrum bolo úspešne pridané.');
      })
      .catch(error => {
        console.error('Error adding center:', error);
        alert('Chyba pri pridávaní centra.');
      });
  };

  const Back = () => {
    navigate('/login');
  };

  return (
    <div
      className="bg-cover bg-center flex justify-center"
      style={{ backgroundImage: "url('/pictures/background.jpg')" }}
    >
      {/* Main Wrapper Div */}
      <div className="p-4 sm:p-8 bg-slate-100 relative w-full flex flex-col">

        {/* Main Content Wrapper */}
        <div className="flex flex-col sm:flex-row sm:gap-4 justify-between items-start rounded-lg p-4 sm:p-8">
          {/* Add Doctor Form */}
          <div className="flex flex-col text-black w-full">
            <h2 className="text-lg font-bold mb-4">Pridaj lekára</h2>

            {/* Form Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

              {/* Name */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-name">Meno:</label>
                <input
                  id="doctor-name"
                  type="text"
                  name="name"
                  value={newDoctor.name}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                />
              </div>

              {/* Center Dropdown */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-center">Centrum:</label>
                <select
                  id="doctor-center"
                  name="centerId"
                  value={newDoctor.centerId}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                >
                  <option value="">Vyberte centrum</option>
                  {centers.map((ambulance) => (
                    <option key={ambulance.centerId} value={ambulance.centerId}>
                      {ambulance.name} - {ambulance.centerId}
                    </option>
                  ))}
                </select>
              </div>

              {/* Typeform URL */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-typeform-url">Typeform Url:</label>
                <input
                  id="doctor-typeform-url"
                  type="text"
                  name="typeformUrl"
                  value={newDoctor.typeformUrl}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                />
              </div>

              {/* Calendar Dropdown */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-calendar-id">Vyber kalendár:</label>
                <select
                  id="doctor-calendar-id"
                  name="calendarId"
                  value={newDoctor.calendarId}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                >
                  <option value="">Vyberte kalendár</option>
                  {calendars.map((calendar) => (
                    <option key={calendar.calendarId} value={calendar.calendarId}>
                      {calendar.calendarId} - {calendar.calendarName}
                    </option>
                  ))}
                </select>
              </div>

              {/* Specialization */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-specialization">Špecializácia:</label>
                <input
                  id="doctor-specialization"
                  type="text"
                  name="specialization"
                  value={newDoctor.specialization}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                />
              </div>

              {/* Info Button Text */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-info-btn-text">Informačný text ku tlačidlu:</label>
                <input
                  id="doctor-info-btn-text"
                  type="text"
                  name="infoBtnText"
                  value={newDoctor.infoBtnText}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                />
              </div>

              {/* Notification Email Address */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-notification-email">Notifikačný email:</label>
                <input
                  id="doctor-notification-email"
                  type="text"
                  name="notificationEmailAddress"
                  value={newDoctor.notificationEmailAddress}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                />
              </div>

              {/* Info Button Link */}
              <div className="mb-2">
                <label className="block mb-1 font-semibold" htmlFor="doctor-info-btn-link">Link pre tlačidlo:</label>
                <input
                  id="doctor-info-btn-link"
                  type="text"
                  name="infoBtnLink"
                  value={newDoctor.infoBtnLink}
                  onChange={handleNewDoctorInputChange}
                  className="w-full border border-gray-400 p-2 rounded"
                />
              </div>

              {/* Express Appointment Checkbox */}
              <div className="mb-2 flex items-center">
                <input
                  id="doctor-express-appointment"
                  type="checkbox"
                  name="expressAppointmentEnabled"
                  checked={newDoctor.expressAppointmentEnabled}
                  onChange={handleNewDoctorInputChange}
                  className="mr-2 w-4 h-4"
                />
                <label className="flex items-center text-xl" htmlFor="doctor-express-appointment">
                  Expresné ceny
                </label>
              </div>

            </div>

            <div className="sm:col-span-2 flex justify-center">
              <button
                className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 mt-4 w-1/2"
                onClick={confirmNewDoctor}
              >
                Pridaj lekára
              </button>
            </div>
          </div>

          {/* Divider */}
          <div className="w-px bg-black mx-1 self-stretch"></div>

          {/* Add Center Section */}
          <div className="flex flex-col text-black w-full sm:w-1/3">
            <h2 className="text-lg font-bold mb-4">Pridaj centrum</h2>

            {/* Názov centra */}
            <div className="mb-4">
              <label className="block mb-1 font-semibold" htmlFor="center-name">Názov centra:</label>
              <input
                id="center-name"
                type="text"
                name="meno"
                value={newCenter.meno}
                onChange={handleNewCenterInputChange}
                className="w-full border border-gray-400 p-2 rounded"
              />
            </div>

            {/* ID centra */}
            <div className="mb-4">
              <label className="block mb-1 font-semibold" htmlFor="center-alias">ID centra:</label>
              <input
                id="center-alias"
                type="text"
                name="alias"
                value={newCenter.alias}
                onChange={handleNewCenterInputChange}
                className="w-full border border-gray-400 p-2 rounded"
              />
            </div>

            <button
              onClick={confirmNewCenter}
              className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
            >
              Pridaj centrum
            </button>
          </div>
        </div>

        {/* Bottom Navigation Buttons */}
        <div className="relative mt-4 sm:absolute sm:bottom-4 sm:left-4 flex justify-center sm:justify-start">
          <button className="bg-blue-600 mr-5 text-white p-4 rounded-full" onClick={Back}>
            <GrFormPrevious className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminAdd;
