import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { GrFormPrevious } from "react-icons/gr";

function AdminData({
    onDateChange,
    onTimeChange,
    onDoctorRemove,
    onAmbulanceRemove
}) {
    const navigate = useNavigate();

    const [centers, setCenters] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedCenterState, setSelectedCenterState] = useState('');
    const [selectedDoctorState, setSelectedDoctorState] = useState('');
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [removeCenterState, setRemoveCenterState] = useState('');
    const [removeDoctorState, setRemoveDoctorState] = useState('');

    const [doctorDetails, setDoctorDetails] = useState({
        doctorId: '',
        name: '',
        specialization: '',
        expressAppointmentEnabled: false,
    });

    const [centerDetails, setCenterDetails] = useState({
        centerId: '',
        centerName: '',
    });

    const baseURL = 'http://144.24.169.245:8080/bezrucovabackend';
    const authToken = localStorage.getItem('authToken');
    
    

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
    };

    const fetchCenters = () => {
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
        fetch(`${baseURL}/centers/getByPermission`, {
            method: 'GET',
            headers
        })
            .then(response => response.json())
            .then(data => {
                setCenters(data);
            })
            .catch(error => console.error('Error fetching centers:', error));
    };

    const fetchDoctors = () => {
        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
        fetch(`${baseURL}/doctors/getByPermission`, {
            method: 'GET',
            headers
        })
            .then(response => response.json())
            .then(data => {
                setDoctors(data);
            })
            .catch(error => console.error('Error fetching doctors:', error));
    };

    useEffect(() => {
        fetchCenters();
        fetchDoctors();
    }, []);

    useEffect(() => {
        const selectedDoc = doctors.find(doc => doc.doctorId === selectedDoctorState);
        setSelectedDoctor(selectedDoc);
    }, [selectedDoctorState, doctors]);

    useEffect(() => {
        const selectedCent = centers.find(cent => cent.centerId === selectedCenterState);
        setSelectedCenter(selectedCent);
    }, [selectedCenterState, centers]);

    useEffect(() => {
        if (selectedDoctor) {
            setDoctorDetails({
                doctorId: selectedDoctor.doctorId || '',
                name: selectedDoctor.name || '',
                specialization: selectedDoctor.specialization || '',
                expressAppointmentEnabled: selectedDoctor.expressAppointmentEnabled || false,
            });
        } else {
            setDoctorDetails({
                doctorId: '',
                name: '',
                specialization: '',
                expressAppointmentEnabled: false,
            });
        }
        if (selectedCenter) {
            setCenterDetails({
                centerId: selectedCenter.centerId || '',
                centerName: selectedCenter.name || '',
            });
        } else {
            setCenterDetails({
                centerId: '',
                centerName: '',
            });
        }
    }, [selectedDoctor, selectedCenter]);

    const handleDoctorInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setDoctorDetails((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleCenterInputChange = (e) => {
        const { name, value } = e.target;
        setCenterDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const confirmCenterUpdate = () => {
        if (!selectedCenter || !selectedCenter.centerId) {
            alert('Prosím, vyberte centrum pre úpravu.');
            return;
        }
    
        if (!centerDetails.centerName.trim()) {
            alert('Názov centra nemôže byť prázdny.');
            return;
        }
    
        const requestBody = {
            alias: selectedCenter.centerId,
            meno: centerDetails.centerName
        };

        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
    
        fetch(`${baseURL}/centers`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(requestBody)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
           // console.log('Center updated:', data);
            fetchCenters();
            alert('Centrum bolo úspešne upravené.');
        })
        .catch(error => {
            console.error('Error updating center:', error);
            alert('Chyba pri aktualizácii centra.');
        });
    };

    const confirmDoctorUpdate = () => {
        if (!selectedDoctor || !selectedDoctor.doctorId) {
            alert('Prosím, vyberte lekára pre úpravu.');
            return;
        }
    
        if (!doctorDetails.name.trim()) {
            alert('Meno lekára nemôže byť prázdne.');
            return;
        }
    
        if (!doctorDetails.specialization.trim()) {
            alert('Špecializácia lekára nemôže byť prázdna.');
            return;
        }
    
        const requestBody = {
            name: doctorDetails.name,
            specialization: doctorDetails.specialization,
            expressAppointmentEnabled: doctorDetails.expressAppointmentEnabled,
        };

        if (!authToken) {
            console.error('No auth token found. Please login.');
            alert('Chyba: Prosím prihláste sa');
            return;
        }
    
        fetch(`${baseURL}/doctors/${selectedDoctor.doctorId}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(requestBody)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
           // console.log('Doctor updated:', data);
            fetchDoctors();
            alert('Lekár bol úspešne upravený.');
        })
        .catch(error => {
            console.error('Error updating doctor:', error);
            alert('Chyba pri aktualizácii lekára.');
        });
    };

    const Back = () => {
        navigate('/login');
    };

    const deleteCenter = () => {
        if (!removeCenterState) {
            alert('Prosím, vyberte centrum na odstránenie.');
            return;
        }
    
        const confirmDelete = window.confirm('Ste si istí, že chcete odstrániť toto centrum?');
        if (confirmDelete) {
            if (!authToken) {
                console.error('No auth token found. Please login.');
                alert('Chyba: Prosím prihláste sa');
                return;
            }
            fetch(`${baseURL}/centers/${removeCenterState}`, {
                method: 'DELETE',
                headers,
            })
            .then(response => {
                if (response.ok) {
                  //  console.log(`Centrum ${removeCenterState} vymazané`);
                    fetchCenters();
                    fetchDoctors();
                    setRemoveCenterState('');
                    setSelectedDoctorState('');
                    setRemoveDoctorState('');
                    setSelectedDoctor(null);
                    alert('Centrum bolo úspešne odstránené.');
                } else {
                    throw new Error('Error removing center');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Chyba pri odstraňovaní centra.');
            });
        }
    };

    const deleteDoctor = () => {
        if (!removeDoctorState) {
            alert('Prosím, vyberte lekára na odstránenie.');
            return;
        }
    
        const confirmDelete = window.confirm('Ste si istí, že chcete odstrániť tohto lekára?');
        if (confirmDelete) {
            fetch(`${baseURL}/doctors/${removeDoctorState}`, {
                method: 'DELETE',
                headers,
            })
            .then(response => {
                if (response.ok) {
                    fetchDoctors();
                    setRemoveDoctorState('');
                    alert('Lekár bol úspešne odstránený.');
                } else {
                    throw new Error('Error removing doctor');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Chyba pri odstraňovaní lekára.');
            });
        }
    };
    

    return (
        <div
            className="bg-cover bg-center flex justify-center"
            style={{ backgroundImage: "url('/pictures/background.jpg')" }}
        >
            {/* Main Wrapper Div */}
            <div className="p-4 sm:p-8 bg-slate-100 relative w-full flex flex-col">
                <div className="w-full flex justify-center bg-opacity-80 bg-slate-100 p-5">
                    {/* You can add a header here if needed */}
                </div>

                {/* Main Content Wrapper */}
                <div className="flex flex-col sm:flex-row sm:gap-4 justify-between items-start rounded-lg p-4 sm:p-8">
                    {/* Left Section - Update Doctor Form */}
                    <div className="flex flex-col text-black w-full sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4">Uprav údaje pre lekára</h2>

                        {/* Doctor Selection Dropdown */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold">Vyberte lekára:</label>
                            <select
                                value={selectedDoctorState}
                                onChange={(e) => setSelectedDoctorState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded"
                            >
                                <option value="">-- Vyberte lekára --</option>
                                {doctors.map((doctor) => (
                                    <option key={doctor.doctorId} value={doctor.doctorId}>
                                        {doctor.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Doctor Name */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold">Meno:</label>
                            <input
                                type="text"
                                name="name"
                                value={doctorDetails.name}
                                onChange={handleDoctorInputChange}
                                className="w-full border border-gray-400 p-2 rounded"
                            />
                        </div>

                        {/* Doctor Specialization */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold">Specializácia:</label>
                            <input
                                type="text"
                                name="specialization"
                                value={doctorDetails.specialization}
                                onChange={handleDoctorInputChange}
                                className="w-full border border-gray-400 p-2 rounded"
                            />
                        </div>

                        {/* Express Appointment Checkbox */}
                        <div className="mb-4">
                            <label className="flex items-center text-xl">
                                <input
                                    type="checkbox"
                                    name="expressAppointmentEnabled"
                                    checked={doctorDetails.expressAppointmentEnabled}
                                    onChange={handleDoctorInputChange}
                                    className="mr-2 w-4 h-4"
                                />
                                Expresné ceny
                            </label>
                        </div>

                        <button
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                            onClick={confirmDoctorUpdate}
                        >
                            Ulož zmeny doktora
                        </button>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Center Update Section */}
                    <div className="flex flex-col text-black w-full sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4">Uprav údaje pre centrum</h2>

                        {/* Center Selection Dropdown */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold">Vyberte centrum:</label>
                            <select
                                value={selectedCenterState}
                                onChange={(e) => setSelectedCenterState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded"
                            >
                                <option value="">-- Vyberte centrum --</option>
                                {centers.map((center) => (
                                    <option key={center.centerId} value={center.centerId}>
                                        {center.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Center Name */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold">Názov centra:</label>
                            <input
                                type="text"
                                name="centerName"
                                value={centerDetails.centerName}
                                onChange={handleCenterInputChange}
                                className="w-full border border-gray-400 p-2 rounded"
                            />
                        </div>

                        <button
                            onClick={confirmCenterUpdate}
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Ulož zmeny centra
                        </button>

                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Right Section - Remove Centers and Doctors */}
                    <div className="flex flex-col text-black w-full sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4">Odstráň centra a lekárov</h2>

                        {/* Centers Dropdown */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold">Centrá:</label>
                            <select
                                value={removeCenterState}
                                onChange={(e) => setRemoveCenterState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded"
                            >
                                <option key="default-center" value="">
                                    Vyberte centrum
                                </option>
                                {centers.map((center) => (
                                    <option key={center.centerId} value={center.centerId}>
                                        {center.name}
                                    </option>
                                ))}
                            </select>
                            <button
                                className="mt-2 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                                onClick={deleteCenter}
                            >
                                Odstráň centrum
                            </button>

                        </div>

                        {/* Doctors Dropdown */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold">Lekári:</label>
                            <select
                                value={removeDoctorState}
                                onChange={(e) => setRemoveDoctorState(e.target.value)}
                                className="w-full border border-gray-400 p-2 rounded"
                            >
                                <option key="default-doctor" value="">
                                    Vyberte lekára
                                </option>
                                {doctors.map((doctor) => (
                                    <option key={doctor.doctorId} value={doctor.doctorId}>
                                        {doctor.name}
                                    </option>
                                ))}
                            </select>
                            <button
                                className="mt-2 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                                onClick={deleteDoctor}
                            >
                                Odstráň lekára
                            </button>

                        </div>
                    </div>
                </div>

                {/* Optional Footer or Additional Content */}
                <div className="w-full flex justify-center bg-opacity-80 bg-slate-100 p-10">
                    {/* Add any footer content here */}
                </div>

                {/* Bottom Navigation Buttons */}
                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:left-4 flex justify-center sm:justify-start">
                    <button className="bg-blue-600 mr-5 text-white p-4 rounded-full" onClick={Back}>
                        <GrFormPrevious className="w-6 h-6" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AdminData;
