import React from 'react';

const ThanksForReserv = ({ formData }) => {
  return (
    <div style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
      <div className="text-center p-8 bg-gray-800 rounded-lg shadow-lg max-w-2xl mx-auto">
        <h1 className="text-xl md:text-3xl lg:text-4xl font-bold text-white">
          Ďakujeme za vašu rezerváciu
        </h1>
        <p className="text-md text-gray-300 mt-4">
          Email bol zaslaný na vašu emailovu adresu <strong>{formData.email}</strong>.
        </p>
      </div>
    </div>
  );
};


export default ThanksForReserv;
