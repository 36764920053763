import dayjs from "dayjs";

export const generateDate = (
    month = dayjs().month(),
    year = dayjs().year()
) => {
    const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
    const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

    const arrayOfDate = [];

    for (let i = 0; i < firstDateOfMonth.day(); i++) {
        const date = firstDateOfMonth.day(i);

        if (date.day() !== 0 && date.day() !== 6) {
            arrayOfDate.push({
                currentMonth: false,
                date,
            });
        }
    }

    for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
        const date = firstDateOfMonth.date(i);
        if (date.day() !== 0 && date.day() !== 6) {
            arrayOfDate.push({
                currentMonth: true,
                date,
                today:
                    date.toDate().toDateString() ===
                    dayjs().toDate().toDateString(),
            });
        }
    }

    const remaining = 35 - arrayOfDate.length; // 5 weekdays * 7 weeks

    for (let i = lastDateOfMonth.date() + 1; arrayOfDate.length < 35; i++) {
        const date = lastDateOfMonth.date(i);
        if (date.day() !== 0 && date.day() !== 6) {
            arrayOfDate.push({
                currentMonth: false,
                date,
            });
        }
    }
    return arrayOfDate;
};

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDate = (date) => {
    const localDate = date.locale('sk');
    const numericDate = localDate.format('D.M.YYYY'); // '11.7.2024'
    let dayOfWeek = localDate.format('ddd'); // 'št'
    let monthYear = localDate.format('MMM YYYY'); // 'júl 2024'

    dayOfWeek = capitalize(dayOfWeek);
    monthYear = capitalize(monthYear);

    return `${numericDate} (${dayOfWeek} ${monthYear})`;
};

export const months = [
    "Január",
    "Február",
    "Marec",
    "Apríl",
    "Máj",
    "Jún",
    "Júl",
    "August",
    "September",
    "Október",
    "November",
    "December",
];


export const findAvailabilityForDate = async (date, calendarId) => {
    if (!calendarId) {
        return { times: [] };
    }

    try {
        const response = await fetch(`http://144.24.169.245:8080/bezrucovabackend/appointment/freeByCalendar/${calendarId}`);
        if (!response.ok) {
            throw new Error('Failed to fetch availability data');
        }

        const availabilityData = await response.json();

        const availability = availabilityData[0].appointments.find(
            appointment => appointment.date === date.format('YYYY-MM-DD')
        );

        return availability ? { times: availability.times } : { times: [] };
    } catch (error) {
        console.error("Error fetching availability data:", error);
        return { times: [] };
    }
};




