import React from 'react';
import AdminAdd from './AdminAdd';
import { useNavigate } from 'react-router-dom';

const AdminAddInfo = ({
    selectedCalendarId,
    onDoctorSelect,
    fastPrices,
    onDateChange,
    onTimeChange,
    resetSelections,
    selectedDoctor,
    selectedAmbulance,
    onAmbulanceSelect,
}) => {

    const navigate = useNavigate();

    const generateSubmit = async (e) => {
        resetSelections();
        navigate('/adminGenerate');
    }
    const modifyTimeslotSubmit = async (e) => {
        resetSelections();
        navigate('/adminModify');
    }
    const administrationPoliklinikaSubmit = async (e) => {
        resetSelections();
        navigate('/adminPrice');
    }
    const administrationDoctorSubmit = async (e) => {
        resetSelections();
        navigate('/adminDoctor');
    }
    const addDoctorSubmit = async (e) => {
        resetSelections();
        navigate('/adminAdd');
    };

    return (
        <div className="flex justify-center items-center p-4 sm:p-8 w-full max-w-screen-xl mx-auto">
            <div className="bg-black bg-opacity-60 p-4 sm:p-8 w-full h-auto text-center">
                <h1
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        color: 'rgb(255, 255, 255)',
                    }}
                    className="mb-4 text-3xl sm:text-3xl lg:text-4xl xl:text-4xl"
                >
                    Pridávanie lekárov a centier
                </h1>
                <p
                    style={{
                        fontWeight: 400,
                        fontFamily: 'Poppins',
                        color: 'rgb(255, 255, 255)',
                        fontStyle: 'normal',
                    }}
                    className="mb-6 text-base sm:text-lg lg:text-xl xl:text-1xl"
                >
                    Vyplňte údaje o centre alebo lekárovi, ktorého chcete pridať
                    <br />
                    Po vyplnení údajov, kliknite na tlačidlo Pridaj
                    <br />
                </p>
                {/* Added buttons styled like time slots */}
                <div className="flex space-x-4 w-full">
                    <button onClick={modifyTimeslotSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Obsadenie termínov
                    </button>
                    <button onClick={generateSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Generovanie termínov
                    </button>
                    <button onClick={administrationPoliklinikaSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Administrácia cien
                    </button>
                    <button onClick={administrationDoctorSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Administrácia lekárov
                    </button>
                    <button onClick={addDoctorSubmit} className="flex-1 h-12 bg-slate-200 text-black font-medium rounded border-4 border-black flex items-center justify-center">
                        Pridaj lekárov
                    </button>
                </div>

                <div className="mt-4 w-full">
                    <AdminAdd
                        onDateChange={onDateChange}
                        onTimeChange={onTimeChange}
                        resetSelections={resetSelections}
                        selectedDoctor={selectedDoctor}
                        selectedAmbulance={selectedAmbulance}
                        fastPrices={fastPrices}
                        selectedCalendarId={selectedCalendarId}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminAddInfo;
