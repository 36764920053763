import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import 'dayjs/locale/sk';
import { generateDate, months } from "../../calendar/util/generateDate.js";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { FaCopy, FaPaste } from 'react-icons/fa';
import cn from "../../calendar/util/cn.js";

const currentDate = dayjs();

function AdminGenerate({
    selectedDoctor,
    selectedCalendarId,
}) {
    const navigate = useNavigate();
    const days = ["Po", "Ut", "St", "Št", "Pi"];

    const [today, setToday] = useState(currentDate);
    const [year, setYear] = useState(currentDate.year());
    const [availability, setAvailability] = useState({ times: [] });
    const [selectDate, setSelectDate] = useState(currentDate);
    const [selectedDuration, setSelectedDuration] = useState(30);
    const [datesAvailability, setDatesAvailability] = useState({});
    const [timeRanges, setTimeRanges] = useState([
        { from: '08:00', to: '09:00' }
    ]);
    const [fromDate, setFromDate] = useState(currentDate.format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(currentDate.format('YYYY-MM-DD'));
    const [selectedDates, setSelectedDates] = useState([]);
    const [copiedWeekDays, setCopiedWeekDays] = useState([]);

    const years = Array.from({ length: 5 }, (_, i) => currentDate.year() - 1 + i);
    const dates = useMemo(() => generateDate(today.month(), year), [today, year]);

    const dateRows = [];
    for (let i = 0; i < dates.slice(0, 25).length; i += 5) {
        dateRows.push(dates.slice(0, 25).slice(i, i + 5));
    }

    const handleDateSelect = (date) => {
        const dateKey = date.format('YYYY-MM-DD');
        setSelectDate(date);

        setSelectedDates(prevSelectedDates => {
            if (prevSelectedDates.includes(dateKey)) {
                return prevSelectedDates.filter(d => d !== dateKey);
            } else {
                return [...prevSelectedDates, dateKey];
            }
        });

        const avail = datesAvailability[dateKey] || { times: [] };
        setAvailability(avail);
    };

    const getBackgroundColorClass = (date) => {
        const dateKey = date.format('YYYY-MM-DD');
        if (selectedDates.includes(dateKey)) {
            return "bg-green-600 text-white";
        }
        const availabilityForDate = datesAvailability[dateKey] || { times: [] };
        return availabilityForDate.times.length > 0 ? "bg-blue-400" : "bg-slate-400";
    };

    const confirmAppointment = async () => {
        if (!selectedDoctor || !selectedCalendarId) {
            alert('Chyba: Prosím vyberte lekára');
            return;
        }

        if (selectedDuration > 59) {
            alert('Chyba: Dĺžka termínu nemôže byť väčšia ako 59 minút');
            return;
        }

        if (selectedDates.length === 0) {
            alert('Chyba: Prosím vyberte aspoň jeden dátum');
            return;
        }

        for (let i = 0; i < timeRanges.length; i++) {
            const { from, to } = timeRanges[i];
            if (dayjs(`2000-01-01 ${from}`).isAfter(dayjs(`2000-01-01 ${to}`))) {
                alert(`Chyba: Čas "Od" je po čase "Do" v časovom rozsahu ${i + 1}`);
                return;
            }
        }

        const data = {
            calendarId: selectedCalendarId,
            duration: selectedDuration,
            dates: selectedDates,
            timeSlots: timeRanges,
        };


        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. Please login.');
                alert('Chyba: Prosím prihláste sa');
                return;
            }

            const response = await fetch('http://144.24.169.245:8080/bezrucovabackend/appointments/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Failed to generate appointments');
            }

            fetchAvailabilityForDoctor();

            setSelectedDates([]);
            alert('Termíny vygenerované úspešne');
        } catch (error) {
            console.error("Error generating appointments:", error);
            alert('Termíny neboli vygenerované');
        }
    };


    const Back = () => {
        navigate('/login');
    };

    const handleTimeRangeChange = (index, field, value) => {
        const newTimeRanges = [...timeRanges];
        newTimeRanges[index][field] = value;
        setTimeRanges(newTimeRanges);
    };

    const addTimeRange = () => {
        setTimeRanges([...timeRanges, { from: '08:00', to: '09:00' }]);
    };

    const removeTimeRange = (index) => {
        const newTimeRanges = timeRanges.filter((_, i) => i !== index);
        setTimeRanges(newTimeRanges);
    };

    const handleCopy = (rowIndex) => {
        const rowDates = dateRows[rowIndex];
        const selectedIndices = rowDates.reduce((indices, { date }, index) => {
            const dateKey = date.format('YYYY-MM-DD');
            if (selectedDates.includes(dateKey)) {
                indices.push(index);
            }
            return indices;
        }, []);
        setCopiedWeekDays(selectedIndices);
       // console.log(`Copied data from row ${rowIndex}`, selectedIndices);
    };

    const handlePaste = (rowIndex) => {
        const rowDates = dateRows[rowIndex];
        const newSelectedDates = [...selectedDates];
        copiedWeekDays.forEach(index => {
            if (index < rowDates.length) {
                const { date } = rowDates[index];
                const dateKey = date.format('YYYY-MM-DD');
                if (!newSelectedDates.includes(dateKey)) {
                    newSelectedDates.push(dateKey);
                }
            }
        });
        setSelectedDates(newSelectedDates);
       // console.log(`Pasted data to row ${rowIndex}`);
    };

    const deleteTimeSlots = async () => {
        const start = dayjs(fromDate);
        const end = dayjs(toDate);

        if (start.isAfter(end)) {
            alert('Chyba: Začiatočný dátum je po koncovom dátume');
            return;
        }

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. Please login.');
                alert('Chyba: Prosím prihláste sa');
                return;
            }

            const response = await fetch('http://144.24.169.245:8080/bezrucovabackend/appointments/deleteWithinInterval', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    calendarId: selectedCalendarId,
                    dateFrom: start.format('YYYY-MM-DD'),
                    dateTo: end.format('YYYY-MM-DD'),
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete appointments');
            }

            const updatedAvailability = { ...datesAvailability };

            let date = start;
            while (date.isBefore(end) || date.isSame(end, 'day')) {
                const dateKey = date.format('YYYY-MM-DD');
                delete updatedAvailability[dateKey];
                date = date.add(1, 'day');
            }
            setDatesAvailability(updatedAvailability);

            await fetchAvailabilityForDoctor();

            if (
                (selectDate.isAfter(start) && selectDate.isBefore(end)) ||
                selectDate.isSame(start, 'day') || selectDate.isSame(end, 'day')
            ) {
                setAvailability({ times: [] });
            }

            alert('Termíny úspešne vymazané');
        } catch (error) {
            console.error("Error deleting appointments:", error);
            alert('Chyba pri vymazaní termínov');
        }
    };


    const fetchAvailabilityForDoctor = async () => {
        if (!selectedDoctor || !selectedCalendarId) {
            setDatesAvailability({});
            setAvailability({ times: [] });
            return;
        }

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. Please login.');
                alert('Chyba: Prosím prihláste sa');
                return;
            }

            const response = await fetch(`http://144.24.169.245:8080/bezrucovabackend/appointments/${selectedCalendarId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch availability data');
            }

            const availabilityData = await response.json();
            const availabilityMap = {};
            availabilityData[0].appointments.forEach(appointment => {
                availabilityMap[appointment.date] = {
                    times: appointment.times,
                };
            });
            setDatesAvailability(availabilityMap);
            setAvailability(availabilityMap[selectDate.format('YYYY-MM-DD')] || { times: [] });
        } catch (error) {
            console.error("Error fetching availability data:", error);
        }
    };


    useEffect(() => {
        fetchAvailabilityForDoctor();
    }, [selectedCalendarId, selectedDoctor, selectDate]);

    return (
        <div className="bg-cover bg-center flex justify-center" style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
            {/* Adjusted to match the layout and height of AdminPrices */}
            <div className="p-4 sm:p-8 bg-slate-100 relative w-full flex flex-col">

                {/* Main Content Wrapper */}
                <div className="flex flex-col sm:flex-row sm:gap-4 justify-between items-start rounded-lg p-4 sm:p-8">
                    {/* Left Section - Calendar */}
                    <div className="w-full sm:w-auto sm:flex-shrink-0">
                        <div className="flex justify-between items-center mb-4 text-black">
                            <div className="flex items-center">
                                <select
                                    value={year}
                                    onChange={(e) => {
                                        setYear(parseInt(e.target.value));
                                        setToday(today.year(parseInt(e.target.value)));
                                    }}
                                    className="select-none font-semibold cursor-pointer bg-slate-100 border border-black text-black rounded-lg p-1"
                                >
                                    {years.map(y => (
                                        <option key={y} value={y}>
                                            {y}
                                        </option>
                                    ))}
                                </select>
                                <h1 className="select-none font-semibold inline ml-2">
                                    {months[today.month()]}
                                </h1>
                            </div>
                            <div className="flex gap-2 items-center font-semibold">
                                <GrFormPrevious
                                    className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        if (today.month() === 0) {
                                            setToday(today.subtract(1, 'year').month(11));
                                            setYear(year - 1);
                                        } else {
                                            setToday(today.subtract(1, 'month'));
                                        }
                                    }}
                                />
                                <h1
                                    className="cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        setToday(currentDate);
                                        setYear(currentDate.year());
                                    }}
                                >
                                    Dnes
                                </h1>
                                <GrFormNext
                                    className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                                    onClick={() => {
                                        if (today.month() === 11) {
                                            setToday(today.add(1, 'year').month(0));
                                            setYear(year + 1);
                                        } else {
                                            setToday(today.add(1, 'month'));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {/* Calendar Header */}
                        <div className="grid grid-cols-6 border-b border-black">
                            <div></div> {/* Placeholder for icons column */}
                            {days.map((day, index) => (
                                <h1 key={index} className="text-sm text-center h-10 w-14 grid place-content-center text-black select-none">
                                    {day}
                                </h1>
                            ))}
                        </div>
                        {/* Calendar Dates */}
                        <div className="grid grid-rows-5">
                            {dateRows.map((row, rowIndex) => (
                                <div key={rowIndex} className="grid grid-cols-6">
                                    {/* Icons */}
                                    <div className="flex items-center justify-center border-t border-black">
                                        <FaCopy
                                            className="cursor-pointer mr-2 hover:text-blue-500"
                                            onClick={() => handleCopy(rowIndex)}
                                        />
                                        <FaPaste
                                            className="cursor-pointer hover:text-blue-500"
                                            onClick={() => handlePaste(rowIndex)}
                                        />
                                    </div>
                                    {/* Dates */}
                                    {row.map(({ date, currentMonth }, index) => {
                                        const isPast = date.isBefore(currentDate, 'day');
                                        const isSelected = selectedDates.includes(date.format('YYYY-MM-DD'));
                                        const bgColorClass = !isPast ? (
                                            isSelected
                                                ? "bg-green-600 text-white"
                                                : getBackgroundColorClass(date)
                                        ) : "text-black cursor-not-allowed bg-slate-400";

                                        const hoverClass = !isPast && !isSelected ? (datesAvailability[date.format('YYYY-MM-DD')]?.times.length > 0 ? "hover:bg-green-400" : "hover:bg-red-300") : "";

                                        return (
                                            <div key={index} className={"p-1 text-center h-14 grid place-content-center text-sm border-t border-black"}>
                                                <h1
                                                    className={cn(
                                                        currentMonth ? "" : "text-white",
                                                        bgColorClass,
                                                        "h-12 w-12 grid place-content-center transition-all cursor-pointer select-none border border-black",
                                                        isPast && "cursor-not-allowed hover:bg-red-300",
                                                        !isSelected && hoverClass
                                                    )}
                                                    onClick={() => {
                                                        if (!isPast) {
                                                            handleDateSelect(date);
                                                        }
                                                    }}
                                                >
                                                    {date.date()}
                                                </h1>
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Middle Section - Timeslots */}
                    <div className="flex flex-col text-black w-full sm:w-2/5">
                        {/* Upper half - Timeslots */}
                        <div>
                            <h1 className="text-lg font-bold mb-4">Aktuálne termíny:</h1>
                            <div className="h-48 overflow-y-auto"> {/* Fixed height for 3x3 grid */}
                                {availability.times.length > 0 ? (
                                    <div className="grid grid-cols-3 grid-rows-3 gap-2">
                                        {availability.times
                                            .sort((a, b) => {
                                                const timeA = a.time.split(":").map(Number);
                                                const timeB = b.time.split(":").map(Number);
                                                return timeA[0] - timeB[0] || timeA[1] - timeB[1];
                                            }).map((timeSlot, index) => (
                                                <div
                                                    key={index}
                                                    className={`p-2 rounded border border-black ${timeSlot.reserved ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}
                                                >
                                                    {timeSlot.time.slice(0, 5)}  {/* Time formatted as HH:MM */}
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    <p>Žiadne dostupné sloty.</p>
                                )}
                            </div>
                        </div>
                        {/* Horizontal divider */}
                        <div className="border-t border-black my-4 self-stretch"></div>
                        {/* Lower half - Date range deletion */}
                        <div>
                            <h1 className="text-lg font-bold mb-4">Odstrániť časové sloty:</h1>
                            <div className="flex flex-col sm:flex-row mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
                                <div className="flex flex-col sm:w-1/2">
                                    <label className="block mb-1 font-semibold">Od dátumu:</label>
                                    <input
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                        className="w-full p-2 border border-black rounded"
                                    />
                                </div>
                                <div className="flex flex-col sm:w-1/2">
                                    <label className="block mb-1 font-semibold">Do dátumu:</label>
                                    <input
                                        type="date"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                        className="w-full p-2 border border-black rounded"
                                    />
                                </div>
                            </div>
                            <button
                                className="w-full sm:w-auto py-2 px-4 rounded bg-red-500 text-white hover:shadow-md"
                                onClick={deleteTimeSlots}
                            >
                                Odstrániť
                            </button>
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="w-px bg-black mx-1 self-stretch"></div>

                    {/* Right Section - Časové intervaly (Time Intervals) */}
                    <div className="flex flex-col text-black w-full sm:w-2/5">
                        {/* Upper Content */}
                        <div>
                            {/* Duration in minutes */}
                            <h1 className="text-lg font-bold mb-4">Časové intervaly:</h1>

                            {/* Duration Input */}
                            <div className="flex flex-col sm:flex-row items-start sm:items-center mb-5 space-y-2 sm:space-y-0 sm:space-x-4">
                                <label className="font-semibold">Dĺžka pridaných termínov:</label>
                                <input
                                    type="number"
                                    value={selectedDuration}
                                    onChange={(e) => setSelectedDuration(parseInt(e.target.value))}
                                    className="w-full sm:w-1/4 p-2 border border-black rounded"
                                />
                            </div>

                            {/* Time Intervals */}
                            <div>
                                {timeRanges.map((range, index) => (
                                    <div key={index} className="flex flex-col sm:flex-row items-start sm:items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
                                        <div className="flex flex-col sm:w-1/2">
                                            <label className="block mb-1 font-semibold">{index === 0 ? 'Od:' : ' '}</label>
                                            <input
                                                type="time"
                                                value={range.from}
                                                onChange={(e) => handleTimeRangeChange(index, 'from', e.target.value)}
                                                className="w-full p-2 border border-black rounded"
                                            />
                                        </div>
                                        <div className="flex flex-col sm:w-1/2">
                                            <label className="block mb-1 font-semibold">{index === 0 ? 'Do:' : ' '}</label>
                                            <input
                                                type="time"
                                                value={range.to}
                                                onChange={(e) => handleTimeRangeChange(index, 'to', e.target.value)}
                                                className="w-full p-2 border border-black rounded"
                                            />
                                        </div>
                                        {index > 0 && (
                                            <div className="flex justify-start sm:justify-center">
                                                <button
                                                    className="text-red-500 mt-2 sm:mt-0"
                                                    onClick={() => removeTimeRange(index)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            {/* Add Time Range Button */}
                            <button
                                className="w-full sm:w-auto py-2 px-4 rounded bg-blue-500 text-white hover:shadow-md mb-4"
                                onClick={addTimeRange}
                            >
                                + Pridať časový rozsah
                            </button>
                        </div>

                        {/* Spacer or Additional Content */}
                        <div className="flex-grow"></div>
                    </div>
                </div>
                <div className="w-full flex justify-center bg-opacity-80 bg-slate-100 p-1">
                </div>

                {/* Bottom Navigation Buttons */}
                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:left-4 flex justify-center sm:justify-start">
                    <button className="bg-blue-600 mr-5 text-white p-4 rounded-full" onClick={Back}>
                        <GrFormPrevious className="w-6 h-6" />
                    </button>
                </div>
                <div className="relative mt-4 sm:absolute sm:bottom-4 sm:right-4 flex justify-center sm:justify-end">
                    <button
                        className={"py-3 px-8 rounded transition-all bg-green-500 text-white hover:shadow-md"}
                        onClick={confirmAppointment}>
                        Ulož
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AdminGenerate;
