import React, { useState, useEffect } from 'react';

const DoctorsHospitalsCalendars = ({ onDoctorSelect, onAmbulanceSelect }) => {
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [ambulances, setAmbulances] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [filteredDoctors, setFilteredDoctors] = useState([]);

    const baseURL = 'http://144.24.169.245:8080/bezrucovabackend';

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');

        fetch(`${baseURL}/centers/getByPermission`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setAmbulances(data);
            })
            .catch(error => console.error('Error fetching ambulances:', error));
    }, [baseURL]);

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        const url = option1
            ? `${baseURL}/doctors/${option1}`
            : `${baseURL}/doctors/getByPermission`;

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setDoctors(data);
                setFilteredDoctors(option1 ? data : []);
            })
            .catch(error => console.error('Error fetching doctors:', error));
    }, [option1, baseURL]);


    const handleAmbulanceChange = (e) => {
        const selectedAmbulance = ambulances.find(ambulance => ambulance.centerId === e.target.value);
        setOption1(selectedAmbulance ? selectedAmbulance.centerId : '');
        onAmbulanceSelect(selectedAmbulance);
        setOption2('');
        onDoctorSelect(null);
        setFilteredDoctors([]);
    };

    const handleDoctorChange = (e) => {
        const selectedDoctor = doctors.find(doctor => doctor.doctorId === e.target.value);
        setOption2(selectedDoctor ? selectedDoctor.doctorId : '');
        onDoctorSelect(selectedDoctor);
    };


    return (
        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
            <div className="w-full md:w-1/2 mx-auto mb-4 md:mb-0">
                <div className="relative">
                    <select
                        id="dropdown1"
                        value={option1}
                        onChange={handleAmbulanceChange}
                        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    >
                        <option value="">-- Vyberte si z ponuky centrum --</option>
                        {ambulances.map((centre, index) => (
                            <option key={index} value={centre.centerId}>
                                {centre.name}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 12l-4-4h8l-4 4z" />
                        </svg>
                    </div>
                </div>
            </div>

            <div className="w-full md:w-1/2">
                <div className="relative">
                    <select
                        id="dropdown2"
                        value={option2}
                        onChange={handleDoctorChange}
                        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    >
                        <option value="">-- Vyberte si z ponuky lekárov --</option>
                        {(option1 ? filteredDoctors : doctors).map((doctor, index) => (
                            <option key={index} value={doctor.doctorId}>
                                {doctor.name} - {doctor.specialization}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 12l-4-4h8l-4 4z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default DoctorsHospitalsCalendars;
