import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GrFormPrevious } from "react-icons/gr";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://144.24.169.245:8080/bezrucovabackend/users/login', {
                username,
                password
            });
            const token = response.headers['x-auth-token'];
            localStorage.setItem('authToken', token);

         //   console.log(token)

            const decodedToken = jwtDecode(token);
            const expirationTime = decodedToken.exp * 1000;

            localStorage.setItem('tokenExpiration', expirationTime);

            setLogoutTimer(expirationTime - Date.now());

            navigate('/adminModify');
        } catch (err) {
            setError(err.response?.data?.message || 'Prihlásenie zlyhalo. Skúste znova.');
        }
    };

    const setLogoutTimer = (milliseconds) => {
        setTimeout(() => {
            alert('Čas prihlásenia vypršal, prosim prihláste sa znova');
            handleLogout();
        }, milliseconds);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('tokenExpiration');
        navigate('/login');
    };

    const Back = () => {
        navigate('/');
    };

    return (
        <div className="relative w-full h-auto flex justify-center items-center">
            <div className="inset-0 bg-black bg-opacity-60 p-8 flex justify-center items-center">
                <button className="bg-blue-600 mr-5 text-white p-4 rounded-full" onClick={Back}>
                    <GrFormPrevious className="w-6 h-6" />
                </button>
                <div className="max-w-lg w-full bg-white rounded-lg p-6 shadow-lg">
                    <h2 className="text-3xl font-semibold mb-6 text-center">Prihlásenie</h2>
                    <p className="text-center mb-6 text-lg">Prosím, zadajte prihlasovacie údaje pre admin konto</p>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-gray-700 text-lg mb-2">
                                Prihlasovacie meno <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-lg text-lg"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-lg mb-2">
                                Heslo <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-lg text-lg"
                                required
                            />
                        </div>
                        <div className="flex justify-between items-center">
                            <button
                                type="submit"
                                className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 text-lg w-full"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                    {error && <p className="text-red-500 text-lg mt-4 text-center">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default Login;