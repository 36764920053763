import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import ReservationForm from './rezervacie/ReservationForm';
import InfoSection from './rezervacie/InfoSection';
import ThanksForReserv from './rezervacie/ThanksForReserv';
import TypeformPreFill from './typeform/TypeformPreFill';
import Login from './admin/Login';
import AdminModifyInfo from './admin/adminModify/AdminModifyInfo';
import AdminGenerateInfo from './admin/adminGenerate/AdminGenerateInfo'
import AdminPricesInfo from './admin/adminPrices/AdminPricesInfo';
import AdminDataInfo from './admin/adminData/AdminDataInfo';
import AdminAddInfo from './admin/adminAdd/AdminAddInfo';


const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedAmbulance, setSelectedAmbulance] = useState('');
    const [selectedCalendarId, setSelectedCalendarId] = useState('');
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [diffSum, setDiffSum] = useState(0);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        psc: '',
        email: '',
        phone: '',
        insurance: '',
        identification_number: '',
        card: '',
        info: '',
        selectedDate: '',
        selectedTime: '',
        totalPrice: '',
        diffSum: '',
        duration: '',
        calendarId: ''
    });

    const [fastPrices, setFastPrices] = useState({
        registracny_poplatok_5day: "119",
        registracny_poplatok_10day: "59",
        registracny_poplatok_other: "0",
        rocna_basic_5day: "119",
        rocna_basic_10day: "39",
        rocna_basic_other: "0",
        rocna_plus_5day: "119",
        rocna_plus_10day: "0",
        rocna_plus_other: "0",
        rocna_max_5day: "0",
        rocna_max_10day: "0",
        rocna_max_other: "0",
        multi_basic_5day: "119",
        multi_basic_10day: "39",
        multi_basic_other: "0",
        multi_plus_5day: "119",
        multi_plus_10day: "0",
        multi_plus_other: "0",
        multi_max_5day: "0",
        multi_max_10day: "0",
        multi_max_other: "0",
    });
    

    const [cenyKariet, setCenyKariet] = useState({
        registracny_poplatok: "69",
        rocna_basic: "119",
        rocna_plus: "179",
        rocna_max: "349",
        multi_basic: "449",
        multi_plus: "699",
        multi_max: "999",
    }); //"69", "119", "449", "179", "699", "349", "999"

    useEffect(() => {
        if (selectedDoctor) {
            const fetchPricesForSelectedDoctor = async () => {
                try {
                    const responsePrices = await fetch(`http://144.24.169.245:8080/bezrucovabackend/prices/findByDoctor/${selectedDoctor.doctorId}`);
                    if (!responsePrices.ok) {
                        throw new Error('Failed to fetch prices data');
                    }
                    const pricesData = await responsePrices.json();

                    const processedFastPrices = {};
                    const processedCenyKariet = {};

                    if (pricesData.vv) {
                        processedFastPrices['registracny_poplatok_5day'] = pricesData.vv[2] || "0";
                        processedFastPrices['registracny_poplatok_10day'] = pricesData.vv[1] || "0";
                        processedCenyKariet['registracny_poplatok'] = pricesData.vv[0] || "0";
                    }

                    if (pricesData.basic) {
                        processedFastPrices['rocna_basic_5day'] = pricesData.basic[2] || "0";
                        processedFastPrices['rocna_basic_10day'] = pricesData.basic[1] || "0";
                        processedFastPrices['rocna_basic_other'] = pricesData.basic[0] || "0";
                    }

                    if (pricesData.plus) {
                        processedFastPrices['rocna_plus_5day'] = pricesData.plus[2] || "0";
                        processedFastPrices['rocna_plus_10day'] = pricesData.plus[1] || "0";
                        processedFastPrices['rocna_plus_other'] = pricesData.plus[0] || "0";
                    }

                    if (pricesData.max) {
                        processedFastPrices['rocna_max_5day'] = pricesData.max[2] || "0";
                        processedFastPrices['rocna_max_10day'] = pricesData.max[1] || "0";
                        processedFastPrices['rocna_max_other'] = pricesData.max[0] || "0";
                    }

                    const responseCards = await fetch(`http://144.24.169.245:8080/bezrucovabackend/cards/${selectedDoctor.doctorId}`);
                    if (!responseCards.ok) {
                        throw new Error('Failed to fetch card prices data');
                    }
                    const cardsData = await responseCards.json();

                    cardsData.forEach(card => {
                        if (card.cardGroup === 'ROCNA') {
                            processedCenyKariet['rocna_basic'] = card.basic;
                            processedCenyKariet['rocna_plus'] = card.plus;
                            processedCenyKariet['rocna_max'] = card.max;
                        } else if (card.cardGroup === 'MULTI') {
                            processedCenyKariet['multi_basic'] = card.basic;
                            processedCenyKariet['multi_plus'] = card.plus;
                            processedCenyKariet['multi_max'] = card.max;
                        }
                    });

                    setFastPrices(processedFastPrices);
                    setCenyKariet(processedCenyKariet);

                } catch (error) {
                    console.error("Error fetching prices data:", error);
                }
            };

            fetchPricesForSelectedDoctor();
        }
    }, [selectedDoctor]);


    const handleFormSubmit = (newFormData) => {
        setFormData(prevData => ({ 
            ...prevData, 
            ...newFormData,
            duration: newFormData.duration || prevData.duration
        }));
    };

    const handleDateChange = (date) => {
        setFormData(prevData => ({
            ...prevData,
            selectedDate: date.format('YYYY-MM-DD')
        }));
    };

    const handleTimeChange = ({ time, duration }) => {
        setFormData(prevData => ({
            ...prevData,
            selectedTime: time,
            duration: duration
        }));
    };

    const handleDoctorSelect = (doctor) => {
        setSelectedDoctor(doctor);
        setSelectedCalendarId(doctor ? doctor.calendarId : '')
        setFormData(prevData => ({
            ...prevData,
            calendarId: doctor ? doctor.calendarId : ''
        }));
    };
    

    const handleAmbulanceSelect = (centre) => {
        setSelectedAmbulance(centre);
        //console.log(centre)
    };

    const handlePriceLabelChange = (priceLabel) => {
        setFormData(prevData => ({
            ...prevData,
            card: priceLabel
        }));
    };

    const handleTotalPriceChange = (totalPrice) => {
        setFormData(prevData => ({
            ...prevData,
            totalPrice: totalPrice
        }));
    };

    const handleCardPriceChange = (cardPrice) => {
        setFormData(prevData => ({
            ...prevData,
            cardPrice: cardPrice
        }));
    };

    useEffect(() => {
        if (location.pathname === '/') {
            resetSelections();
        }
    }, [location.pathname]);

    const resetSelections = () => {
        setSelectedDoctor('');
        setSelectedAmbulance('');
        setSelectedPrice(null);
        setDiffSum(0);
        setFormData({
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            psc: '',
            email: '',
            phone: '',
            insurance: '',
            identification_number: '',
            info: '',
            selectedDate: '',
            selectedTime: '',
            card: '',
            diffSum: 0,
            totalPrice: '',
            cardPrice: '',
            duration: ''
        });
    };
    

    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            diffSum: diffSum
        }));
    }, [diffSum]);

 /*   useEffect(() => {
       // console.log(formData)
    }, [formData]);

    useEffect(() => {
       // console.log(fastPrices)
    }, [fastPrices]);

    useEffect(() => {
        console.log(selectedDoctor)
     }, [selectedDoctor]);

     useEffect(() => {
        console.log(selectedAmbulance)
     }, [selectedAmbulance]);
*/

    const handleAvatarClick = () => {
        resetSelections();
        navigate('/login');
    };

    return (
        <div className="relative min-h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: "url('/pictures/background.jpg')" }}>
           {location.pathname === '/' && (
                <div className="absolute top-6 right-6">
                    <img 
                        src="/pictures/admin.png"
                        alt="Admin Avatar" 
                        className="w-20 h-20 rounded-full cursor-pointer"
                        onClick={handleAvatarClick}
                    />
                </div>
            )} 
             
                <Routes>
                    <Route path="/" element={
                            <InfoSection 
                                onDoctorSelect={handleDoctorSelect} 
                                onAmbulanceSelect={handleAmbulanceSelect} 
                                onDateChange={handleDateChange} 
                                onTimeChange={handleTimeChange} 
                                onPriceLabelChange={handlePriceLabelChange}
                                onTotalPriceChange={handleTotalPriceChange}
                                onCardPriceChange={handleCardPriceChange}
                                resetSelections={resetSelections}
                                selectedDoctor={selectedDoctor}
                                selectedAmbulance={selectedAmbulance}
                                selectedPrice={selectedPrice}
                                setDiffSum={setDiffSum}
                                fastPrices={fastPrices}
                                selectedCalendarId={selectedCalendarId}
                                cenyKariet = {cenyKariet}
                            />
                    } />
                    <Route path="/rezervacia" element={<ReservationForm onFormSubmit={handleFormSubmit} formData={formData} selectedDoctor={selectedDoctor}/>} 
                    />
                    <Route path="/typeform" element={<TypeformPreFill formData={formData} selectedDoctor={selectedDoctor}/>} 
                    />
                    <Route path="/email" element={<ThanksForReserv formData={formData}/>}/>   
                    <Route path="/login" element={<Login />} />
                    <Route path="/adminModify" element={<AdminModifyInfo                                 
                                onDoctorSelect={handleDoctorSelect} 
                                onAmbulanceSelect={handleAmbulanceSelect} 
                                onDateChange={handleDateChange} 
                                onTimeChange={handleTimeChange}
                                resetSelections={resetSelections}
                                selectedDoctor={selectedDoctor}
                                selectedAmbulance={selectedAmbulance}
                                selectedCalendarId={selectedCalendarId} 
                                />} 
                                />
                    <Route path="/adminGenerate" element={<AdminGenerateInfo                                 
                                onDoctorSelect={handleDoctorSelect} 
                                onAmbulanceSelect={handleAmbulanceSelect} 
                                onDateChange={handleDateChange} 
                                onTimeChange={handleTimeChange} 
                                resetSelections={resetSelections}
                                selectedDoctor={selectedDoctor}
                                selectedAmbulance={selectedAmbulance}
                                selectedCalendarId={selectedCalendarId} 
                                />} 
                                />  
                    <Route path="/adminPrice" element={<AdminPricesInfo                                
                                onDoctorSelect={handleDoctorSelect} 
                                onAmbulanceSelect={handleAmbulanceSelect} 
                                onDateChange={handleDateChange} 
                                onTimeChange={handleTimeChange} 
                                onPriceLabelChange={handlePriceLabelChange}
                                onTotalPriceChange={handleTotalPriceChange}
                                onCardPriceChange={handleCardPriceChange}
                                resetSelections={resetSelections}
                                selectedDoctor={selectedDoctor}
                                selectedAmbulance={selectedAmbulance}
                                selectedPrice={selectedPrice}
                                setDiffSum={setDiffSum}
                                fastPrices={fastPrices}
                                selectedCalendarId={selectedCalendarId} 
                                setFastPrices = {setFastPrices}
                                setCenyKariet = {setCenyKariet}
                                />} 
                                />      
                    <Route path="/adminDoctor" element={<AdminDataInfo                                
                                onDoctorSelect={handleDoctorSelect} 
                                onAmbulanceSelect={handleAmbulanceSelect} 
                                onDateChange={handleDateChange} 
                                onTimeChange={handleTimeChange} 
                                resetSelections={resetSelections}
                                selectedDoctor={selectedDoctor}
                                selectedAmbulance={selectedAmbulance}
                                selectedCalendarId={selectedCalendarId} 
                                />} 
                                />          
                    <Route path="/adminAdd" element={<AdminAddInfo                               
                                onDoctorSelect={handleDoctorSelect} 
                                onAmbulanceSelect={handleAmbulanceSelect} 
                                onDateChange={handleDateChange} 
                                onTimeChange={handleTimeChange} 
                                resetSelections={resetSelections}
                                selectedDoctor={selectedDoctor}
                                selectedAmbulance={selectedAmbulance}
                                selectedCalendarId={selectedCalendarId} 
                                />} 
                                />               
                </Routes>
                
        </div>
    );
};

export default App;